import image1 from '../img/projectImages/myfriends.gif';
import image2 from '../img/projectImages/blognode.png';
import image3 from '../img/projectImages/crudreactnode.png';
import image4 from '../img/projectImages/simple-logineregister.png';
import image5 from '../img/projectImages/bike-landingpage.png';
import image6 from '../img/projectImages/pxghelper.png';
import image7 from '../img/projectImages/venus.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default [image1, image2, image3, image4, image5, image6, image7];
